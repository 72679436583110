import React from 'react'
import { get, debounce } from 'lodash'
import axios from 'axios'
import { Form } from 'informed'
import { withRouter } from 'react-router-dom'
import FormContainer from '../../containers/Form'
import { Item as defaults } from '../../reducers/defaults'
import { BaseForm } from '../../base'
import {
  InputSelect,
  InputText,
  InputCheckbox,
  InputSelectAsyncCreatable
} from '../../components/inputs'
import { API_HOST } from '../../consts'
import Loading from '../../components/Loading'

const fonteOptions = [
  {
    label: 'Não sou autor do item',
    value: false
  },
  {
    label: 'Sou autor do item',
    value: true
  }
]
const mapResponse = ( { id, descricao } ) => ( {
  value: id,
  label: descricao
} )
class Fonte extends BaseForm {
  static defaultProps = {
    fonte: defaults.fonte
  };

  constructor( props ) {
    super( props )
    this.onValueChange = this.onValueChange.bind( this )
    this.fetchPalavrasChave = this.fetchPalavrasChave.bind( this )
    this.callApi = false
    this.onSubmit = this.onSubmit.bind( this )
    this.fields = [
      'criado_pelo_autor',
      'autor',
      'exame_fonte',
      'dificuldade',
      'ano',
      'publico'
    ]
    this.multiFields = ['palavras_chave']
  }

  metadataModels = ['item'];

  state = {
    exame: [],
    dificuldade: [],
    save: this.ATUALIZADO,
    fetchingData: true,
    data: {}
  };

  componentDidMount () {
    this.fetchApi( 'dificuldade', 'itens' )
    this.fetchApi( 'exame', 'core' )
    this.fetchData()
    this.fetchMetadata()
  }

  getPalavrasChave ( item ) {
    const palavras_chave = get( item, 'palavras_chave' )

    if ( palavras_chave ) {
      return palavras_chave.split( ',' ).map( ( x ) => ( { label: x, value: x } ) )
    }

    return []
  }

  fetchData () {
    if ( this.id !== 'new' ) {
      return axios
        .get( `${API_HOST}/itens/item/${this.id}/form` )
        .then( ( response ) => {
          if ( this.formApi.fieldExists( 'palavras_chave' ) ) {
            this.formApi.setValue(
              'palavras_chave',
              this.getPalavrasChave( response )
            )
          }
          this.setState( { fetchingData: false, data: response.data }, () => {
            this.setFields( response.data )
            this.callApi = true
          } )
          return response.data
        } )
    }

    return null
  }



  fetchPalavrasChave = debounce( ( input, callback ) => {
    axios
      .get( `${API_HOST}/itens/palavra_chave/generate`, {
        descricao: input
      } )
  }, 1000 )

  onValueChange = debounce( ( values ) => {
    this.setState( { save: this.SALVANDO } )

    const data = this.getSubmitData( values )
    if ( Array.isArray( data.palavras_chave ) ) {
      data.palavras_chave = data.palavras_chave.map( ( x ) => x.label ).join( ',' )
    } else {
      delete data.palavras_chave
    }

    axios
      .patch( `${API_HOST}/itens/item/${this.id}`, data )
      .then( () => {
        this.setState( { save: this.SALVO } )
      } )
      .catch( () => {
        this.setState( { save: this.ERRO } )
      } )
  }, 500 );

  renderForm () {
    const criado_pelo_autor = get(
      this.state,
      'formState.values.criado_pelo_autor'
    )
    const { exame } = this.state

    if ( criado_pelo_autor === false ) {
      return (
        <div className="row">
          {this.shouldDisplay( 'item.exame_fonte' ) && (
            <InputSelect
              required={this.isRequired( 'item.exame_fonte' )}
              col="col-sm-5"
              label="Instrumento"
              field="exame_fonte"
              options={exame}
            />
          )}
          {this.shouldDisplay( 'item.autor' ) && (
            <InputText
              required={this.isRequired( 'item.autor' )}
              col="col-sm-5"
              label="Autor"
              field="autor"
            />
          )}
          {this.shouldDisplay( 'item.ano' ) && (
            <InputText
              required={this.isRequired( 'item.ano' )}
              col="col-sm-5"
              label="Ano"
              field="ano"
            />
          )}
        </div>
      )
    }

    if ( criado_pelo_autor === true ) {
      return (
        <div className="row">
          <InputCheckbox label="Deixar este item público" field="publico" />
        </div>
      )
    }

    return null
  }

  onSubmit ( url ) {
    axios
      .get( `${API_HOST}/itens/item/${this.id}/validate?form=fonte` )
      .then( () => {
        const { history } = this.props
        history.push( `/itens/${this.id}/${url}` )
      } )
      .catch( ( error ) => {
        this.onValidateError( error, 'fonte' )
      } )
  }

  render () {
    const { dificuldade, fetchingData, save, data } = this.state
    const { permission } = this.props

    const disabledButtons =
      !get( permission, '__all__' ) && get( data, 'criado_pela_encomenda' )
        ? true
        : false
    return (
      <FormContainer
        title="Autoria"
        next="pendencias"
        previous="conteudo"
        onClick={this.onSubmit}
        disabled={save === this.MODIFICADO}
      >
        <Form
          onChange={( formState ) => this.setState( { formState } )}
          onValueChange={( values ) => {
            if ( this.callApi ) {
              this.setState( { save: this.MODIFICADO } )
              this.onValueChange( values )
            }
          }}
          getApi={( formApi ) => {
            this.formApi = formApi
          }}
        >
          {() => {
            if ( fetchingData ) {
              return <Loading />
            }

            return (
              <React.Fragment>
                {this.renderSaveStatus()}
                <div className="row">
                  <InputSelect
                    col="col-sm-4"
                    label="Autor da questão"
                    field="criado_pelo_autor"
                    options={fonteOptions}
                  />
                </div>

                {this.renderForm()}

                <hr />
                <div className="row">
                  {this.shouldDisplay( 'item.dificuldade' ) && (
                    <InputSelect
                      col="col-sm-6"
                      label="Dificuldade presumida"
                      field="dificuldade"
                      disabled={disabledButtons}
                      options={dificuldade}
                      required={this.isRequired( 'item.dificuldade' )}
                    />
                  )}
                  {this.shouldDisplay( 'item.palavras_chave' ) && (
                    <InputSelectAsyncCreatable
                      multi
                      col="col-sm-6"
                      label="Palavras-chave"
                      field="palavras_chave"
                      loadOptions={this.fetchPalavrasChave}
                      autoload={false}
                      required={this.isRequired( 'item.palavras_chave' )}
                    />
                  )}
                </div>
              </React.Fragment>
            )
          }}
        </Form>
      </FormContainer>
    )
  }
}

export default withRouter( Fonte )

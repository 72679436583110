import axios from 'axios'
import { get, size } from 'lodash'
import queryString from 'query-string'
import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import BaseList from '../../base/BaseList'
import AddButton from '../../components/buttons/AddButton'
import FilterBox from '../../components/FilterBox'
import Item from '../../components/Item'
import ListItemDetailed from '../../components/list/ListItemDetailed'
import Loading from '../../components/Loading'
import { API_HOST } from '../../consts'
import NavBreadCrumb from '../../containers/NavBreadCrumb'
import FiltroAvancadoItens from './FiltroAvancadoItens'
import ItemActionFeedbackModal from './ItemActionFeedbackModal'

class ListaItens extends BaseList {
  constructor( props ) {
    super( props )
    this.expandAll = this.expandAll.bind( this )
    this.toggleModal = this.toggleModal.bind( this )
    this.enviarRevisao = this.enviarRevisao.bind( this )
    this.params = {}
    this.api = `${API_HOST}${props.api}`
    this.state = {
      fetchingData: false,
      data: [],
      matriz: [],
      competencia: [],
      habilidade: [],
      dificuldade: [],
      page_size: 10,
      page: 1,
      collapsed: true,
      expandAllState: false,
      search: '',
      itensValidos: [],
      itensInvalidos: [],
      response: [],
      isAvancado: false,
      ordering: '-criado_em',
      filtros: [],
      itensInativados: {},
      filterBoxes: []
    }
  }

  removeConfirmationMessage = ( id ) =>
    `Tem certeza que deseja remover a encomenda ${id}?`;

  componentDidMount () {
    const { location } = this.props
    const search = queryString.parse( location.search )
    if ( search.id ) {
      this.params = {
        id: search.id,
        ...this.params
      }
    }
    if ( search.page ) {
      this.setState( { page: search.page }, this.fetchData )
    } else {
      this.fetchData()
    }
  }

  fetchDataCallback ( results ) {
    if ( results.length > 0 ) {
      this.fetchPermissions()
    }
  }

  expandAll () {
    this.setState( {
      expandAllState: !this.state.expandAllState
    } )
  }

  changePage = ( pagination ) => {
    const { location, history } = this.props
    this.setState( { fetchingData: true } )
    const page = pagination.selected + 1
    this.setState( { page }, this.fetchData )
    const search = queryString.stringify( {
      ...queryString.parse( location.search ),
      page
    } )
  };

  getDropdownList () {
    return [
      {
        id: 1,
        title: 'Imprimir',
        url: '/',
        key: 'action'
      },
      {
        id: 2,
        title: 'Exportar',
        url: '/',
        key: 'action'
      },
      {
        id: 3,
        title: 'Enviar para revisão',
        onClick: this.enviarRevisao,
        key: 'action'
      },
      {
        id: 4,
        title: 'Excluir',
        url: '/',
        key: 'action'
      }
    ]
  }

  fetchPermissions () {
    const { data } = this.state
    axios
      .get( `${API_HOST}/itens/item/permissions`, {
        params: {
          id: data.map( ( d ) => d.id ).join( ',' )
        }
      } )
      .then( ( response ) => {
        const permissions = response.data

        this.setState( {
          data: data.map( ( d ) => ( {
            ...d,
            permissions: get( permissions, d.id )
          } ) )
        } )
      } )
  }

  renderPagination () {
    const { page, data, total_pages, fetchingData, total, page_size } =
      this.state
    if ( fetchingData ) return null

    if ( data.length > 0 ) {
      return (
        <div className="row" style={{ textAlign: 'center' }}>
          <div className="col-md-3">
            <Select
              matchPos="auto"
              className="filter-size"
              value={page_size}
              options={[
                { value: '5', label: '5 Itens' },
                { value: '10', label: '10 Itens' },
                { value: '25', label: '25 Itens' },
                { value: '50', label: '50 Itens' }
              ]}
              clearable={false}
              onChange={this.handleChangeNumberItens}
              searchable={false}
            />
          </div>
          <div className="col-md-3 col-lg-3 col-xs-3" style={{ fontSize: 12 }}>
            <p style={{ marginBottom: 5, marginTop: 10 }}>
              Mostrando <b>{data.length}</b> de <b>{total}</b> registros
            </p>
          </div>
          <div className="col-6">
            <ReactPaginate
              // className="pull-right"
              pageCount={total_pages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLabel="ANTERIOR"
              nextLabel="PRÓXIMA"
              onPageChange={( pagination ) => this.changePage( pagination )}
              initialSelected={page}
              forcePage={page - 1}
              containerClassName="pagination"
              activeClassName="active"
              disabledClassName="disabled"
              disableInitialCallback
              breakLabel={<button className="disabled">...</button>}
            />
          </div>
        </div>
      )
    }

    return null
  }

  handleChangeNumberItens = ( value ) => {
    this.setState( { page_size: get( value, 'value' ) }, this.fetchData )
  };

  iniciarRevisao ( data ) {
    axios
      .get(
        `${API_HOST}/revisoes/revisao/${get(
          data,
          'encomenda.id'
        )}/iniciar_revisao_item`,
        {
          params: {
            item: data.item
          }
        }
      )
      .then( ( response ) => {
        const { history } = this.props
        history.push( `/revisoes/${response.data.id}/edit` )
      } )
      .catch( ( error ) => {
        console.error( error.response )
        if ( error.response && error.response.status === 404 ) {
          toast.warn( 'Não há nenhum item disponível para revisão.' )
        } else if ( error.response && error.response.status === 400 ) {
          toast.warn( error.response.data[0] )
        } else {
          toast.error(
            'Não foi possível iniciar uma revisão. Por favor, tente novamente.'
          )
        }
      } )
  }

  fetchEncomendasVinculadas ( item ) {
    axios
      .get( `${API_HOST}/encomenda/elaborador_item`, {
        params: {
          item: item,
          elaborador__encomenda__status_id: 2
        }
      } )
      .then( ( response ) => {
        this.iniciarRevisao( response.data[0] )
      } )
      .catch( ( error ) => {
        console.error( error )
      } )
  }

  getActions = ( item ) => {
    const id = get( item, 'id' )
    const permissions = get( item, 'permissions' ) || []
    const actions = []

    // Opção de revisar via lista de itens desabilitada temporariamente
    if ( get( permissions, 'revisar' ) ) {
      actions.push(
        <button
          key="0"
          className="btn btn-white mb-3 "
          onClick={() => this.fetchEncomendasVinculadas( id )}
        >
          REVISAR
        </button>
      )
    }

    if ( get( permissions, 'update' ) ) {
      actions.push(
        <Link className="btn btn-white mb-3" key="1" to={`/itens/${id}/dados`}>
          <i className="fas fa-edit" />
        </Link>
      )
    }

    // if (get(permissions, 'destroy')) {
    //   actions.push(
    //     <button
    //       key="2"
    //       className="btn btn-white mb-3"
    //       onClick={() => this.handleRemove(id)}
    //     >
    //       <i className="fas fa-times" />
    //     </button>
    //   );
    // }

    return actions
  };

  removeItem ( id ) {
    axios.delete( `${API_HOST}/itens/item/${id}` ).then( () => {
      toast.success( `Item ${id} removido com sucesso.` )
      this.fetchData( false )
    } )
  }

  doAction = ( { endpoint, modal } ) => {
    const checkboxes = Array.from(
      document.querySelectorAll( '[id^="checkbox-"]:checked' )
    )
    const itens = checkboxes.map( ( c ) => c.id.split( '-' )[1] )

    if ( itens.length === 0 ) {
      toast.error( 'Por favor, selecione um ou mais itens.' )
      return
    }

    axios
      .post( `${API_HOST}${endpoint}`, { itens } )
      .then( ( response ) => {
        this.setState( {
          itensValidos: response.data.itens_validos,
          itensInvalidos: response.data.itens_invalidos,
          openModal: modal
        } )
        this.fetchData( false )
        Array.from(
          document.querySelectorAll( '[id^="checkbox-"]:checked' )
        ).forEach( ( c ) => {
          // eslint-disable-next-line
          c.checked = false
        } )

        document.querySelector( '#selecionar-todos' ).checked = false
      } )
      .catch( () => {
        toast.error( 'Ocorreu um erro. Por favor, tente novamente.' )
      } )
  };

  enviarRevisao () {
    this.doAction( {
      endpoint: '/itens/item/enviar_revisao',
      modal: 'respostaEnviarRevisao'
    } )
  }

  inativar = () => {
    this.doAction( {
      endpoint: '/itens/item/inativar',
      modal: 'inativar'
    } )
  };

  ativar = () => {
    this.doAction( {
      endpoint: '/itens/item/ativar',
      modal: 'ativar'
    } )
  };

  getItemTitle ( item ) {
    const tipo = get( item, 'tipo.descricao' )
    const areas_conhecimento = get( item, 'areas_conhecimento' )
    let title = `Item ${item.id}`
    if ( size( areas_conhecimento ) > 0 ) {
      title += ` - ${areas_conhecimento.join( ' - ' )} `
    }
    if ( tipo ) {
      title += ` - ${tipo}`
    }
    return title
  }

  renderList () {
    const { fetchingData, data } = this.state
    if ( fetchingData ) return <Loading />

    return data.map( ( item ) => (
      <ListItemDetailed
        key={item.id}
        title={this.getItemTitle( item )}
        status={{
          label: get( item, 'status.descricao', '-' ),
          color: get( item, 'status.cor' )
        }}
        description={[
          { label: 'Elaborador:', value: get( item, 'criado_por' ) || '-' },
          {
            label: 'Dificuldade presumida:',
            value: get( item, 'dificuldade.descricao', '-' )
          }
        ]}
        // startCollapsed={expandAllState}
        startCollapsed
        atualizado_em={item.atualizado_em}
        versao={item.versao}
        actions={this.getActions( item )}
        id={item.id}
      >
        <Item item={item} />
      </ListItemDetailed>
    ) )
  }

  renderAddButton () {
    const permissions = get( this.props, 'permissions' ) || {}
    const getAllPermissions = get( permissions, '__all__' )
    return (
      <Link to="/itens/new/dados">
        {getAllPermissions || get( permissions, 'itens.view_button_new_item' ) ? (
          <AddButton icon="plus" title="Novo item" />
        ) : null}
      </Link>
    )
  }

  renderToolbar () {
    const { search } = this.state

    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="container-check-custom">
            <input
              type="checkbox"
              id="selecionar-todos"
              aria-label=""
              onChange={this.selecionarTodos}
            />
            <span className="checkmark" />
          </label>
          {this.renderAddButton()}
          <div>
            <UncontrolledDropdown>
              <DropdownToggle caret className="btn-white ml-2">
                Ações
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Imprimir</DropdownItem>
                <DropdownItem>Exportar</DropdownItem>
                <DropdownItem onClick={this.enviarRevisao}>
                  Enviar para Revisão
                </DropdownItem>
                {this.hasPermission( 'itens.inativar_item' ) && (
                  <DropdownItem onClick={this.inativar}>Inativar</DropdownItem>
                )}
                {this.hasPermission( 'itens.ativar_item' ) && (
                  <DropdownItem onClick={this.ativar}>Ativar</DropdownItem>
                )}
                <DropdownItem>Excluir</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div className="d-flex">
          <div className="search mr-2">
            <input
              className="search-term"
              placeholder="Código"
              value={search}
              onChange={( event ) => {
                const { value } = event.target
                this.setState( { search: value } )
                this.debouncedFetchData( value )
              }}
            />
          </div>
          <div>
            <button
              type="button"
              onClick={() => this.toggleModal( 'filtroAvancado' )}
              className="btn btn-white"
            >
              <i className="fas fa-filter" /> Filtro
            </button>
          </div>
          {this.renderOrderingSelect()}
        </div>
      </div>
    )
  }

  toggleModal ( name ) {
    const { openModal } = this.state
    this.setState( { openModal: openModal === name ? null : name } )
  }

  limparFiltros = () => {
    this.setState( { isAvancado: false } )
    this.params = {}
    const { history, location } = this.props
    history.push( location.pathname )
    this.fetchData()
  };

  renderBreadcrumb () {
    const { title } = this.props
    return <NavBreadCrumb currentPath={title} hasBackslash={false} />
  }

  buscarItens = ( v ) => {
    const values = { ...v }
    if (
      Array.isArray( values.palavras_chave ) &&
      values.palavras_chave.length > 0
    ) {
      values.palavras_chave = values.palavras_chave
        .map( ( item ) => item.label )
        .join( '|' )
    }
    this.params = { ...values }
    this.fetchData()
  };

  selecionarTodos ( event ) {
    const { checked } = event.target
    Array.from( document.querySelectorAll( '[id^="checkbox-"]' ) ).forEach(
      ( checkbox ) => {
        // eslint-disable-next-line
        checkbox.checked = checked
      }
    )
  }

  updateFilterBoxes = ( filterBoxes ) => {
    this.setState( { filterBoxes } )
  };

  removeFilter ( field ) {
    this.setState( { filterToRemove: field } )
  }

  render () {
    const {
      openModal,
      itensValidos,
      itensInvalidos,
      filterBoxes,
      filterToRemove
    } = this.state

    return (
      <div>
        {this.renderBreadcrumb()}
        <div className="container">
          {this.renderToolbar()}
          {filterBoxes.map( ( d ) => (
            <FilterBox
              key={d.field}
              onClick={() => this.removeFilter( d.field )}
              className="mr-1 mb-1"
              {...d}
            />
          ) )}
          {Object.keys( this.params ).length > 0 && (
            <div className="row">
              <div className="col-2">
                <button
                  className="btn btn-white mb-3"
                  onClick={this.limparFiltros}
                >
                  <i className="fas fa-eraser" /> Limpar todos os filtros
                </button>
              </div>
            </div>
          )}
          {this.renderList()}
          {this.renderPagination()}
          {this.renderRemoveConfirmationModal()}
          <FiltroAvancadoItens
            isOpen={openModal === 'filtroAvancado'}
            toggle={() => this.toggleModal( 'filtroAvancado' )}
            buscarItens={this.buscarItens}
            updateFilterBoxes={this.updateFilterBoxes}
            filterToRemove={filterToRemove}
          />
          <ItemActionFeedbackModal
            isOpen={openModal === 'respostaEnviarRevisao'}
            toggle={() => this.toggleModal( 'respostaEnviarRevisao' )}
            valid={itensValidos}
            invalid={itensInvalidos}
            title="Enviar itens para revisão"
            validTitle="Itens enviados"
            invalidTitle="Itens que não puderam ser enviados"
          />
          <ItemActionFeedbackModal
            isOpen={openModal === 'inativar'}
            toggle={() => this.toggleModal( 'inativar' )}
            valid={itensValidos}
            invalid={itensInvalidos}
            title="Inativar itens"
            validTitle="Itens inativados"
            invalidTitle="Itens que não puderam ser inativados"
          />

          <ItemActionFeedbackModal
            isOpen={openModal === 'ativar'}
            toggle={() => this.toggleModal( 'ativar' )}
            valid={itensValidos}
            invalid={itensInvalidos}
            title="Ativar itens"
            validTitle="Itens ativados"
            invalidTitle="Itens que não puderam ser ativados"
          />
        </div>
      </div>
    )
  }
}

export default ListaItens

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { get, map, size } from 'lodash';
import { Form, Scope } from 'informed';
import axios from 'axios';
import DeleteButton from '../../components/DeleteButton';
import Alert from '../../components/Alert';
import { BaseForm } from '../../base';
import { InputSelect } from '../../components/inputs';
import { API_HOST, UserContext } from '../../consts';
import NxtButton from '../../components/NxtButton';
import PrevButton from '../../components/PrevButton';
import Loading from '../../components/Loading';
import { getCompetencias, getHabilidades } from '../../utils';

const mapCompetencia = (c) => ({
  value: c.id,
  label: c.descricao,
  ...c
});

class MatrizReferencia extends BaseForm {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addMatrizReferencia = this.addMatrizReferencia.bind(this);
    this.formApis = {};
    this.fields = ['matriz_referencia'];
    this.multiFields = ['competencias'];
  }

  state = {
    matrizes_referencia: [],
    matriz_referencia: [],
    area_conhecimento_ids: [],
    competencia: [],
    habilidade: [],
    fetchingData: true,
    fetchingItem: true,
    fetchingMatrizesReferencia: true,
    showNoMatrizReferenciaAlert: true,
    save: this.ATUALIZADO,
    markedForDeletion: [],
    data: {}
  };

  metadataModels = ['item'];

  componentDidMount() {
    this.fetchMetadata().then(() => {
      this.fetchData();
      this.fetchItem();
      this.fetchCompetencias();
    });
  }

  fetchCompetencias() {
    axios.get(`${API_HOST}/matriz_referencia/competencia`).then((response) => {
      const data = response.data.results
        ? response.data.results
        : response.data;
      this.setState({ competencia: data.map(mapCompetencia) });
    });
  }

  fetchMatrizesReferencia() {
    const { area_conhecimento_ids } = this.state;
    const params = {
      status: 1,
      page_size: 100
    };

    if (this.shouldDisplay('item.areas_conhecimento')) {
      params.areas_conhecimento__id = area_conhecimento_ids.join('|');
    }

    axios
      .get(`${API_HOST}/matriz_referencia/matriz_referencia`, { params })
      .then((response) => {
        const data = response.data.results
          ? response.data.results
          : response.data;
        this.setState({
          matriz_referencia: data.map((x) => ({
            value: x.id,
            label: x.nome,
            ...x
          })),
          fetchingMatrizesReferencia: false
        });
      });
  }

  fetchItem() {
    axios
      .get(`${API_HOST}/itens/item/${this.props.idItem}`)
      .then((response) => {
        const { tipo, areas_conhecimento, area_conhecimento_ids, exame } =
          response.data;
        this.setState(
          {
            tipo,
            areas_conhecimento,
            area_conhecimento_ids,
            exame,
            data: response.data,
            fetchingItem: false
          },
          this.fetchMatrizesReferencia
        );
      });
  }

  fetchData() {
    this.callApi = false;

    axios
      .get(`${API_HOST}/itens/item_matriz_referencia/form`, {
        params: {
          item: this.props.idItem
        }
      })
      .then((response) => {
        this.setState(
          { matrizes_referencia: response.data, fetchingData: false },
          () => {
            response.data.forEach(() => {
              this.formApi.setValues({ matrizes_referencia: response.data });
            });
            setTimeout(() => {
              this.callApi = true;
            }, 1000);
          }
        );
      });
  }

  renderEmptyState() {
    const { matrizes_referencia } = this.state;

    if (matrizes_referencia.length === 0) {
      return (
        <div>
          <hr />
          <div>
            <p>Este item ainda não possui nenhuma matriz de referência.</p>
          </div>
        </div>
      );
    }
    return '';
  }

  add() {
    const { matrizes_referencia } = this.state;
    this.setState({
      matrizes_referencia: [{ id: null }].concat(matrizes_referencia)
    });
  }

  remove(id) {
    axios.delete(`${API_HOST}/itens/item_matriz_referencia/${id}`).then(() => {
      const { matrizes_referencia } = this.state;
      this.setState({
        matrizes_referencia: matrizes_referencia.filter((x) => x.id !== id)
      });
    });
  }

  addCompetencia(i) {
    this.setState({ save: this.MODIFICADO });
    const { values } = this.formApi.getState();
    const competencias = get(
      values,
      `matrizes_referencia[${i}].competencias`,
      []
    ).concat([{}]);
    values.matrizes_referencia[i].competencias = competencias;
    this.formApi.setValues({ ...values });
  }

  addMatrizReferencia() {
    this.setState({ save: this.MODIFICADO });
    const { values } = this.formApi.getState();
    const matrizes_referencia = get(values, 'matrizes_referencia', []).concat([
      {}
    ]);
    this.formApi.setValues({ matrizes_referencia });
  }

  removeCompetencia(i, j) {
    this.setState({ save: this.MODIFICADO });
    const { values } = this.formApi.getState();
    values.matrizes_referencia[i].competencias.splice(j, 1);
    this.formApi.setValues({ ...values });
  }

  removeMatrizReferencia(m, i) {
    if (m.id) {
      const { markedForDeletion } = this.state;
      markedForDeletion.push(m.id);
      this.setState({ markedForDeletion });
    }

    const { values } = this.formApi.getState();
    values.matrizes_referencia.splice(i, 1);
    this.formApi.setValues({ ...values });
  }

  handleSubmit(next) {
    const { values } = this.formApi.getState();
    const matrizes_referencia = get(values, 'matrizes_referencia') || [];
    const data = matrizes_referencia.map((d) => ({
      ...d,
      item: this.props.idItem
    }));
    const { markedForDeletion } = this.state;

    axios
      .post(`${API_HOST}/itens/item_matriz_referencia`, data, {
        params: {
          delete: markedForDeletion.join(',')
        }
      })
      .then(() => {
        const { history } = this.props;
        history.push(next);
      });
  }

  getCompetencias(m) {
    const { matriz_referencia } = this.state;
    return getCompetencias(matriz_referencia, m);
  }

  getHabilidades(m, c) {
    const { matriz_referencia } = this.state;
    return getHabilidades(matriz_referencia, m, c);
  }

  showNoAreaConhecimentoSelectedMessage() {
    const { area_conhecimento_ids } = this.state;
    const areaConhecimentoEnabled = this.shouldDisplay(
      'item.areas_conhecimento'
    );
    return areaConhecimentoEnabled && size(area_conhecimento_ids) === 0;
  }

  showNoAreaConhecimentoAvailableMessage() {
    const {
      showNoMatrizReferenciaAlert,
      fetchingMatrizesReferencia,
      matriz_referencia
    } = this.state;
    const areaConhecimentoEnabled = this.shouldDisplay(
      'item.areas_conhecimento'
    );
    return (
      areaConhecimentoEnabled &&
      showNoMatrizReferenciaAlert &&
      !fetchingMatrizesReferencia &&
      size(matriz_referencia) === 0
    );
  }

  render() {
    const {
      matriz_referencia,
      fetchingData,
      fetchingMetadata,
      fetchingItem,
      fetchingMatrizesReferencia,
      data
    } = this.state;
    const permissions = get(this.props, 'permissions') || {};
    const disabledButtons =
      !get(permissions, '__all__') && get(data, 'criado_pela_encomenda')
        ? true
        : false;
    return (
      <div>
        <Form
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
          initialValues={{
            matrizes_referencia: []
          }}
        >
          {({ formState }) => {
            if (
              fetchingData ||
              fetchingMetadata ||
              fetchingItem ||
              fetchingMatrizesReferencia
            ) {
              return <Loading />;
            }

            if (this.showNoAreaConhecimentoSelectedMessage()) {
              return (
                <div>
                  <p className="text-center text-normal">
                    <i className="fas fa-info-circle" /> Por favor, antes de
                    prosseguir, <Link to="dados">clique aqui</Link> para definir
                    uma área de conhecimento.
                  </p>
                </div>
              );
            }

            return (
              <React.Fragment>
                {this.showNoAreaConhecimentoAvailableMessage() && (
                  <div className="row">
                    <div className="col-md-12 padding-15">
                      <Alert
                        category="warning"
                        onClick={() =>
                          this.setState({ showNoMatrizReferenciaAlert: false })
                        }
                      >
                        Nenhuma matriz de referência relacionada às áreas de
                        conhecimento do item.
                      </Alert>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn primary-button"
                      onClick={this.addMatrizReferencia}
                      disabled={disabledButtons}
                    >
                      <i className="fas fa-plus" /> Adicionar matriz
                    </button>
                  </div>
                </div>
                {map(formState.values.matrizes_referencia, (m, i) => (
                  <Scope scope={`matrizes_referencia[${i}]`}>
                    <div className="card matriz-referencia mt-3">
                      <div className="card-body">
                        <div className="row">
                          <InputSelect
                            col="col-sm-11"
                            label="Matriz de Referência"
                            field="matriz_referencia"
                            disabled={disabledButtons}
                            options={matriz_referencia}
                            clearable={false}
                          />
                          {get(data, 'criado_pela_encomenda') ? null : (
                            <DeleteButton
                              onClick={() => this.removeMatrizReferencia(m, i)}
                            />
                          )}
                        </div>
                        {m.matriz_referencia && (
                          <div className="row">
                            <div className="col-md-12">
                              <button
                                className="btn primary-button"
                                onClick={() => this.addCompetencia(i)}
                                disabled={disabledButtons}
                              >
                                <i className="fas fa-plus" /> Adicionar
                                competência e habilidade
                              </button>
                            </div>
                          </div>
                        )}
                        {get(
                          formState,
                          `values.matrizes_referencia[${i}].competencias`,
                          []
                        ).length > 0 && <hr />}
                        {map(
                          formState.values.matrizes_referencia[i].competencias,
                          (c, j) => (
                            <React.Fragment>
                              <div className="row">
                                <Scope scope={`competencias[${j}]`}>
                                  <InputSelect
                                    col="col-sm-11"
                                    label="Competência"
                                    field="competencia"
                                    disabled={disabledButtons}
                                    options={this.getCompetencias(m)}
                                    onChange={() => {
                                      const { matrizes_referencia } =
                                        formState.values;
                                      matrizes_referencia[i].competencias[
                                        j
                                      ].habilidades = [];
                                      this.formApi.setValues({
                                        matrizes_referencia
                                      });
                                    }}
                                  />
                                  {get(data, 'criado_pela_encomenda') ? null : (
                                    <DeleteButton
                                      onClick={() =>
                                        this.removeCompetencia(i, j)
                                      }
                                    />
                                  )}
                                  <InputSelect
                                    multi
                                    col="col-sm-11"
                                    label="Habilidades"
                                    field="habilidades"
                                    disabled={disabledButtons}
                                    options={this.getHabilidades(m, c)}
                                  />
                                </Scope>
                              </div>
                              {i <
                                formState.values.matrizes_referencia[i]
                                  .competencias.length -
                                  1 && <hr />}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </Scope>
                ))}

                <div className="row pull-right mt-2 mb-2">
                  <div className="col-md-12">
                    <PrevButton
                      to="dados"
                      onClick={() => this.handleSubmit('dados')}
                    />
                    <NxtButton
                      onClick={() =>
                        this.handleSubmit('referencias-bibliograficas')
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Form>
      </div>
    );
  }
}

export default (props) => (
  <UserContext.Consumer>
    {({ permissions, user }) => (
      <MatrizReferencia permissions={permissions} user={user} {...props} />
    )}
  </UserContext.Consumer>
);

import React from 'react';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { get, map } from 'lodash';
import LabelView from './LabelView';
import Loading from './Loading';
import { API_HOST } from '../consts';
import ItemConteudo from './ItemConteudo';

class Item extends React.Component {
  static defaultProps = {
    hasParametro: true,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  state = {
    activeTab: 'conteudo',
    matrizes_referencia: [],
    fetchingMatrizesReferencia: true,
    historicoUtilizacao: {},
    fetchingHistoricoUtilizacao: true,
  };

  toggle(tab) {
    this.setState({ activeTab: tab });

    const { fetchingMatrizesReferencia, fetchingHistoricoUtilizacao } = this.state;

    if (tab === 'matrizes_referencia' && fetchingMatrizesReferencia) {
      this.fetchMatrizesReferencia();
    }

    if (tab === 'historico_utilizacao' && fetchingHistoricoUtilizacao) {
      this.fetchHistoricoUtilizacao();
    }
  }

  renderAreasConhecimento() {
    const areas_conhecimento = get(this.props, 'item.areas_conhecimento', []);

    if (areas_conhecimento.length === 0) {
      return '-';
    }

    return (
      <ul className="style-list">
        {areas_conhecimento.map(a => <li key={a}>{a}</li>)}
      </ul>
    );
  }

  renderDados() {
    return (
      <div className="row">
        <div className="col-sm-4">
          <LabelView
            title="Áreas do conhecimento" text={this.renderAreasConhecimento()} />
        </div>
        <div className="col-sm-4">
          <LabelView title="Exame" text={get(this.props, 'item.exame.descricao')} />
        </div>
        <div className="col-sm-4">
          <LabelView title="Tipo de item" text={get(this.props, 'item.tipo.descricao')} />
        </div>
      </div>
    );
  }

  fetchMatrizesReferencia() {
    axios.get(`${API_HOST}/itens/item_matriz_referencia`, {
      params: {
        item: get(this.props, 'item.id'),
        ordering: '-id',
      }
    })
      .then((response) => {
        this.setState({
          matrizes_referencia: response.data,
          fetchingMatrizesReferencia: false,
        })
      })
  }

  fetchHistoricoUtilizacao() {
    axios.get(`${API_HOST}/analises/analise_item/historico_utilizacao`, {
      params: {
        item: get(this.props, 'item.id')
      }
    })
      .then((response) => {
        this.setState({
          historicoUtilizacao: response.data,
          fetchingHistoricoUtilizacao: false,
        })
      })
      .catch((e) => {
        this.setState({
          fetchingHistoricoUtilizacao: false,
        })
      })
  }

  renderMatrizesReferencia() {
    const matrizes_referencia = get(this.state, 'matrizes_referencia', []);
    const { fetchingMatrizesReferencia } = this.state;

    if (fetchingMatrizesReferencia) return <Loading />;
    if (matrizes_referencia.length === 0) return (
      <p className="azul"><i className="fas fa-exclamation-circle" /> Este item não possui matrizes de referência.</p>

    );

    return (
      <div>
        <ul className="style-list">
          {matrizes_referencia.map(m => (
            <li key={m.id}>
              {get(m, 'matriz_referencia', '-')}
              <ul className="style-list">
                {map(m.competencias, c => (
                  <React.Fragment key={c.competencia}>
                    <li>{c.competencia}</li>
                    <ul className="style-list">{map(c.habilidades, h => <li>{h}</li>)}</ul>
                  </React.Fragment>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderPalavrasChave() {
    let palavras_chave = get(this.props, 'item.palavras_chave');
    palavras_chave = palavras_chave ? palavras_chave.split(',') : [];

    if (palavras_chave.length === 0) {
      return '-';
    }

    return map(palavras_chave, (x, i) => <span key={`${x}${i}`} className="badge badge-secondary mr-1">{x}</span>);
  }

  renderFonte() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <LabelView title="Ano" text={get(this.props, 'item.ano', '-')} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <LabelView title="Autor" text={get(this.props, 'item.autor', '-')} />
          </div>
          <div className="col-sm-6">
            <LabelView title="Instrumento de avaliação" text={get(this.props, 'item.instrumento.descricao', '-')} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <LabelView title="Dificuldade presumida" text={get(this.props, 'item.dificuldade.descricao', '-')} />
          </div>
          <div className="col-sm-6">
            <LabelView
              title="Palavras-chave"
              text={this.renderPalavrasChave()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBibliografias() {
    const bibliografias = get(this.props, 'item.bibliografias', []);

    if (bibliografias.length === 0) {
      return (
        <p className="azul"><i className="fas fa-exclamation-circle" /> Este item não possui referências bibliográficas.</p>

      )
    }

    return (
      <div>
        {
          bibliografias.map((b, i) => (
            <React.Fragment key={b.id}>
              {i > 0 && <hr />}
              <div className="row">
                <div className="col-md-6">
                  <LabelView title="Título" text={get(b, 'titulo', '-')} />
                </div>
                <div className="col-md-6">
                  <LabelView title="Autor" text={get(b, 'autor', '-')} />
                </div>
                <div className="col-md-4">
                  <LabelView title="Capítulo" text={get(b, 'capitulo', '-')} />
                </div>
                <div className="col-md-4">
                  <LabelView title="Página inicial" text={get(b, 'pagina_inicial', '-')} />
                </div>
                <div className="col-md-4">
                  <LabelView title="Página final" text={get(b, 'pagina_final', '-')} />
                </div>
                <div className="col-md-12">
                  <LabelView title="Link" text={get(b, 'link', '-')} />
                </div>
              </div>
            </React.Fragment>
          ))
        }
      </div>
    );
  }

  renderParametros() {
    const item = get(this.props, 'item') || {};

    if (!item.calibrado) {
      return (
        <p className="azul"><i className="fas fa-exclamation-circle" /> Este item não foi calibrado.</p>
      );
    }

    return (
      <React.Fragment>
        <div className="row">
          <LabelView col="col-md-4" title="Bisserial" text={get(item, 'bisserial', '-')} />
          <LabelView col="col-md-4" title="Discriminação" text={get(item, 'discriminacao', '-')} />
          <LabelView col="col-md-4" title="Dificuldade (porcentagem de acertos)" text={get(item, 'acertos', '-')} />
          <LabelView col="col-md-4" title="A (discriminação)" text={get(item, 'pa', '-')} />
          <LabelView col="col-md-4" title="B (dificuldade)" text={get(item, 'pb', '-')} />
          <LabelView col="col-md-4" title="C (acerto casual)" text={get(item, 'pc', '-')} />
        </div>
      </React.Fragment>
    );
  }

  renderHistoricoUtilizacao() {
    const { historicoUtilizacao } = this.state;
    const { fetchingHistoricoUtilizacao } = this.state;

    if (fetchingHistoricoUtilizacao) return <Loading />;
    if (Object.keys(historicoUtilizacao).length === 0) return (
      <p className="azul"><i className="fas fa-exclamation-circle" /> Este item não foi utilizado.</p>

    );

    return (
      <React.Fragment>
        {historicoUtilizacao.analisados.map(item => (
          <>
            <div className="card mb-10" key={item.id}>

              <label>{get(item, 'avaliacao_name', '-')}</label>
              <div className='row mx-auto'>
                <LabelView col="col-md-4" title="Bisserial" text={get(item, 'bisserial', '-')} />
                <LabelView col="col-md-4" title="Discriminação" text={get(item, 'discriminacao', '-')} />
                <LabelView col="col-md-4" title="Dificuldade (porcentagem de acertos)" text={`${get(item, 'dificuldade', '-')}%`} />
                <LabelView col="col-md-4" title="A (discriminação)" text={get(item, 'pa', '-')} />
                <LabelView col="col-md-4" title="B (dificuldade)" text={get(item, 'pb', '-')} />
                <LabelView col="col-md-4" title="C (acerto casual)" text={get(item, 'pc', '-')} />
              </div>
            </div>
            <br />
          </>
        ))}
        {historicoUtilizacao.nao_analisados.map(item => (
          <>
            <div className="card" key={item.id}>
              <dl >
                <label>{get(item, 'avaliacao_name', '-')}</label>
                <div className='col-sm-9'>Não foi realizada análise</div>
              </dl>
            </div>
            <br />
          </>
        ))}

      </React.Fragment>
    );
  }

  render() {
    const { labels, hasParametro, border } = this.props;
    const borderStyle = border ? {} : { border: 'none' };

    return (
      <div className="card-item card-alone" style={{ minWidth: '100%', marginLeft: 0 }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'conteudo' })}
              onClick={() => this.toggle('conteudo')}
            >
              Conteúdo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'dados' })}
              onClick={() => this.toggle('dados')}
            >
              Dados
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'matrizes_referencia' })}
              onClick={() => this.toggle('matrizes_referencia')}
            >
              Matrizes de referência
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'fonte' })}
              onClick={() => this.toggle('fonte')}
            >
              Autoria
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'bibliografias' })}
              onClick={() => this.toggle('bibliografias')}
            >
              Bases Teóricas
            </NavLink>
          </NavItem>
          {
            hasParametro &&
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === 'parametros' })}
                onClick={() => this.toggle('parametros')}
              >
                Parâmetros
              </NavLink>
            </NavItem>
          }
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 'historico_utilizacao' })}
              onClick={() => this.toggle('historico_utilizacao')}
            >
              Histórico de Utilização
            </NavLink>
          </NavItem>
        </Nav>

        <div className="card-body no-radius-top" style={borderStyle}>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="conteudo">
              <ItemConteudo labels={labels} item={this.props.item} />
            </TabPane>
            <TabPane tabId="dados">
              {this.renderDados()}
            </TabPane>
            <TabPane tabId="matrizes_referencia">
              {this.renderMatrizesReferencia()}
            </TabPane>
            <TabPane tabId="fonte">
              {this.renderFonte()}
            </TabPane>
            <TabPane tabId="bibliografias">
              {this.renderBibliografias()}
            </TabPane>
            {
              hasParametro &&
              <TabPane tabId="parametros">
                {this.renderParametros()}
              </TabPane>
            }
            <TabPane tabId="historico_utilizacao">
              {this.renderHistoricoUtilizacao()}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default Item;
